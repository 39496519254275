import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import {Link} from "gatsby";
import {HiOutlineArrowCircleRight} from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../constants";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../ReadMore";
import {SemFocusedDesign} from "../icons/SemFocusedDesign";
import {SemLandingPages} from "../icons/SemLandingPages";
import {SemMonitoring} from "../icons/SemMonitoring";
import {SemTargetedKeywords} from "../icons/SemTargetedKeywords";
import Benefits from "./Benefits";
import {useMediaQuery} from "react-responsive";

const Sem = (props, ref) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS['md'] });
  return (
    <Wrapper {...props} ref={ref}>
      <Container className="animate-in-section">
        <Title className="animate-in-slide-right">
          {isMobile ? 'SEM.' : 'Search Engine Marketing.'}
        </Title>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Paragraph className="animate-in-slide-right">
              There is much more to making a website successful than just creation. Sites require promotion
              through popular search engines to ensure your presence is felt at the top of the listings.
              Search Engine Marketing (SEM) is the science behind site promotion. JL Tech’s site experts use the
              latest tools to update keywords and content for maximum exposure.
            </Paragraph>
            <ReadMore to={PATHS.sem} className="animate-in-slide-right">Read more</ReadMore>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Benefits trigger={`#${props.id}`} items={[
              {label: 'Designed for SEM', icon: <SemFocusedDesign />},
              {label: 'Landing Pages', icon: <SemLandingPages />},
              {label: 'Keyword Targeting', icon: <SemTargetedKeywords />},
              {label: 'SEM Monitoring', icon: <SemMonitoring />},
            ]} />
          </Col>
        </Content>
      </Container>
      <Background className="animate-in-background">
        <StaticImage
          src="../../images/sem_hero.jpg"
          alt="Unified end to end solutions."
          loading="lazy"
          transformOptions={{grayscale: true}}
          placeholder="blurred"
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};
export default forwardRef(Sem);

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(26, 18, 88, .9) 0%, rgba(26, 18, 88, .9) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    overflow: visible;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 111px;
  margin-bottom: 100px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 6vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 50px;
`;


