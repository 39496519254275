import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../constants";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../ReadMore";
import {DevCustomized} from "../icons/DevCustomized";
import {DevSeo} from "../icons/DevSeo";
import {DevCleanCode} from "../icons/DevCleanCode";
import {DevResponsive} from "../icons/DevResponsive";
import Benefits from "./Benefits";

const Development = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container className="animate-in-section">
        <Title className="animate-in-slide-right">
          Development.
        </Title>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Paragraph className="animate-in-slide-right">
              Developing a web presence is more than putting words on a page. Today’s websites require experience
              with styles and coding techniques to make pages really pop. JL Tech’s developers are well-versed in
              the most popular development languages. They have the experience to translate your ideas into clean,
              attractive displays of your products and services.
            </Paragraph>
            <ReadMore to={PATHS.development} className="animate-in-slide-right">Read more</ReadMore>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Benefits trigger={`#${props.id}`} items={[
              {label: 'Fully Customized', icon: <DevCustomized />},
              {label: 'Built for SEO', icon: <DevSeo />},
              {label: 'Clean Code', icon: <DevCleanCode />},
              {label: 'Responsive', icon: <DevResponsive />},
            ]} />
          </Col>
        </Content>
      </Container>
      <Background className="animate-in-background">
        <StaticImage
          src="../../images/develop_hero.jpg"
          alt="Unified end to end solutions."
          loading="eager"
          transformOptions={{grayscale: true}}
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Development);

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(241, 240, 245, .5) 0%, rgba(241, 240, 245, 1) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    overflow: visible;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .9);
  text-transform: capitalize;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
`;


