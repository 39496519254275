import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, ImageRight, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";

const Unique = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 64}}>
          <Col xs={24} sm={14} md={14} lg={12} xl={12}>
            <Title className="animate-in-slide-up">Unique.</Title>
            <Paragraph className="animate-in-slide-up">
              Every business is different, and so is our approach. We tailor our services to each individual
              business to suit their needs in order to get the most out of your marketing efforts.
              No more one-size-fits-all philosophies. You are not a copy of your competitors, and your
              marketing strategy shouldn't be either.
            </Paragraph>
          </Col>
          <Col xs={24} sm={10} md={10} lg={12} xl={12}>
            <ImageRight className="animate-in-slide-left">
              <StaticImage
                src={"../../images/marketing_2.jpg"}
                layout="constrained"
                alt="unique design for your needs"
                loading="eager"
                placeholder="blurred"
                aspectRatio="1"
              />
            </ImageRight>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Unique);

const Wrapper = styled(Section)`
  position: relative;
  background: rgba(255, 255, 255, .75);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(241, 240, 245, .5) 0%, rgba(241, 240, 245, 1) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 100px;
    margin-bottom: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 6vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .9);
  text-transform: capitalize;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
`;
