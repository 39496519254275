import React from "react";
import Design from "./Design";
import Development from "./Development";
import Header from "./Header";
import Help from "./Help";
import Sem from "./Sem";
import Drupal from "./Drupal";
import Layout from '../Layout'
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import Solutions from "./Solutions";
import Unique from "./Unique";
import HorizontalScroll from "../HorizontalScroll";
import Contact from "../contact";

const Index = () => {
  const underlay = () => {
    return (
      <>
        <Background>
          <StaticImage
            src="../../images/marketing_hero.jpg"
            alt="Marketing Services"
            loading="eager"
          />
        </Background>
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech Marketing - Design, Development & SEO/SEM > Any Platform",
      desc: `Promoting your business, especially online, is a difficult task for
        any business. Between creating a design, managing content, and
        promoting that over search engines and other avenues, there is a
        mountain of steps to take. Your business needs a partner that can
        navigate that mountain, and set you up at the top. JL Tech is that
        partner.`,
      keywords: `app developers near me, website developers coeur d'alene, drupal developers coeur d'alene, 
        react developers coeur d'alene, app developers coeur d'alene, website developers spokane, website designers spokane, 
        website designers coeur d'alene, website developers near me, website designers near me, app developers spokane, 
        react developers spokane, drupal developers spokane, gatsby developers spokane, jltech, cda`
    }} snap>
      <Header id="header" />
      <Help id="help" />
      <Solutions id="solutions" />
      <HorizontalScroll id="marketing-services">
        <Design id="design" />
        <Development id="development" />
        <Sem id="sem" />
        <Drupal id="drupal" />
      </HorizontalScroll>
      <Unique id="unique" />
      <Chat />
    </Layout>
  );
};

export default Index;

const Background = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;