import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {AiOutlineArrowLeft} from "@react-icons/all-files/ai/AiOutlineArrowLeft";
import {AiOutlineArrowUp} from "@react-icons/all-files/ai/AiOutlineArrowUp";
import {AiOutlineArrowRight} from "@react-icons/all-files/ai/AiOutlineArrowRight";
import {AiOutlineArrowDown} from "@react-icons/all-files/ai/AiOutlineArrowDown";
import {H5} from "../../styles/Global";
import {useMediaQuery} from "react-responsive";
import {BREAKPOINTS} from "../../constants";

const Benefits = ({trigger, items, offset, ...props}) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS['lg'] });
  const scrollerRef = useRef(null);

  const [state, setState] = useState({
    scroller: null,
    itemHeight: 0,
    isPrevHidden: false,
    isNextHidden: false
  });

  const next = () => {
    state.scroller.scrollBy({left: 0, top: state.itemHeight, behavior: 'smooth'});
    setState({...state});
  }

  const prev = () => {
    state.scroller.scrollBy({left: 0, top: -state.itemHeight, behavior: 'smooth'});
    setState({...state});
  }

  useEffect(() => {
    const scroller = scrollerRef.current;
    const itemHeight = scrollerRef.current.firstElementChild?.clientHeight;
    setState({...state, scroller, itemHeight});

    return () => {}
  },[]); // eslint-disable-line

  return (
    <Wrapper {...props}>
      <TitleWrapper>
        <Linedot />
        <Title>
          Benefits of JLTech
        </Title>
      </TitleWrapper>
      <Items ref={scrollerRef} className="benefits-items">
        {items.map((item, index) => {
          return (
            <Item key={index}>
              <ItemLabel>{item.label}</ItemLabel>
              <ItemIcon>
                {item.icon}
              </ItemIcon>
            </Item>
          );
        })}
      </Items>
      <PrevButton onClick={() => prev()} hide={state.isPrevHidden}>
        {isMobile ? <AiOutlineArrowLeft /> : <AiOutlineArrowUp /> }
      </PrevButton>
      <NextButton onClick={() => next()} hide={state.isNextHidden}>
        {isMobile ? <AiOutlineArrowRight /> : <AiOutlineArrowDown /> }
      </NextButton>
      <Background />
    </Wrapper>
  );
};

export default Benefits;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-top: -100px;
    margin-left: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    margin-top: -200px;
    margin-left: 100px;
  }
`;

const TitleWrapper = styled.div`
  color: #fff;
  position: absolute;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  top: 0px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    top: 50%;
    right: 60px;
    transform-origin: top right;
    transform: rotate(-90deg) translateX(50%);
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    right: 120px;
  }
`;

const Linedot = styled.div`
  height: 40px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: -40px;
  right: 50%;
  
  &&:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 0%;
    right: -2px;
  }
`;

const Title = styled.div`
  font-size: 10px;
`;


const Items = styled.div`
  height: 200px;
  width: 300px;
  overflow-y: scroll;
  
  /* Hide scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 300px;
    width: 300px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 600px;
    width: 600px;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ItemLabel = styled.div`
  ${H5}
  color: rgba(255, 61, 0, 1);
  position: absolute;
  width: 100%;
  top: 20px;
  white-space: nowrap;
  text-align: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    top: 50%;
    right: 30px;
    transform-origin: top right;
    transform: rotate(-90deg) translateX(50%);
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    right: 60px;
  }
`;

const ItemIcon = styled.div`
  width: 100px;
  height: 100px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 250px;
    width: 250px;
  }
`;

const PrevButton = styled.button`
  display: ${props => props.hide ? 'none' : 'block'};
  background: transparent;
  border: 0;
  padding: 10px;
  font-size: 24px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  height: 50px;
  color: rgba(255, 61, 0, 1);
  left: 0;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    left: auto;
    top: -30px;
    margin: 0 auto;
  }
`;

const NextButton = styled(PrevButton)`
  left: auto;
  right: 0;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    right: auto;
    top: auto;
    bottom: -30px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255,255,255, 0.15) 0%, rgba(255,255,255, 0) 50%, rgba(255,255,255,0) 0%);
  z-index: -1;
`;