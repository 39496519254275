import React from 'react'
import Index from '../components/marketing'

 const marketing = (props) => {
    return (
        <>
            <Index {...props} />
        </>
    )
}
export default marketing;