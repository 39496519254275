import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const Help = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title className="animate-in-slide-up">Here to help.</Title>
          <Paragraph className="animate-in-slide-up">
            Promoting your business, especially online, is a difficult task for any business. Between creating a
            design, managing content, and promoting that over search engines and other avenues, there are a mountain
            of steps to take. Your business needs a partner that can navigate that mountain and set you up at the top.
            JLTech is that partner.
          </Paragraph>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Help);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, .5);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 60%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 50%;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
