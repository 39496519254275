import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../constants";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../ReadMore";
import Benefits from "./Benefits";
import {DesignUnified} from "../icons/DesignUnified";
import {DesignModern} from "../icons/DesignModern";
import {DesignUserFriendly} from "../icons/DesignUserFriendly";
import {DesignFunctional} from "../icons/DesignFunctional";

const Design = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container className="animate-in-section">
        <Title className="animate-in-slide-right">
          Design.
        </Title>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Paragraph className="animate-in-slide-right">
              Design is the foundation that everything else sits on. The structure of content, the way it flows,
              and its ease of use are vital to the performance of your marketing content, whether online or in
              print. The designers at JL Tech have years of experience, and continually learn new trends and
              techniques to make your content a functional, attractive reality.
            </Paragraph>
            <ReadMore to={PATHS.design} className="animate-in-slide-right">Read more</ReadMore>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Benefits trigger={`#${props.id}`} items={[
              {label: 'Unified', icon: <DesignUnified />},
              {label: 'Modern', icon: <DesignModern />},
              {label: 'User Friendly', icon: <DesignUserFriendly />},
              {label: 'Functional', icon: <DesignFunctional />},
            ]} />
          </Col>
        </Content>
      </Container>
      <Background className="animate-in-background">
        <StaticImage
          src="../../images/design_hero.jpg"
          alt="Unified end to end solutions."
          loading="eager"
          transformOptions={{grayscale: true}}
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Design);

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(26, 18, 88, .9) 0%, rgba(26, 18, 88, .9) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    overflow: visible;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 111px;
  margin-bottom: 100px;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 6vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 50px;
`;




