import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import {Row, Col} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import {MarketingEnd2End} from "../icons/MarketingEnd2End";
import {MarketingUnified} from "../icons/MarketingUnified";

const Solutions = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 30, lg: 64, xl: 256}}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Icon className="animate-in-slide-up"><MarketingEnd2End /></Icon>
            <Title className="animate-in-slide-up">End-to-End</Title>
            <Paragraph className="animate-in-slide-up">
              We believe your business shouldn't be limited, so we don't limit our services. We work hard to provide an end-to-end solution for everything marketing, so that your business always gets the best.
            </Paragraph>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Icon className="animate-in-slide-up"><MarketingUnified /></Icon>
            <Title className="animate-in-slide-up">Unified</Title>
            <Paragraph className="animate-in-slide-up">
              A key factor in growth is a unified presence. Whether it be in print or digital, JL Tech builds all of your marketing material with a unified look and feel that promotes recognizability.
            </Paragraph>
          </Col>
        </Content>
      </Container>
      <Background className="animate-in-background">
        <StaticImage
          src="../../images/crew_3@2x.jpg"
          alt="Unified end to end solutions."
          loading="eager"
          transformOptions={{grayscale: true}}
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Solutions);

const Wrapper = styled(Section)`
  position: relative;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(241, 240, 245, .5) 0%, rgba(241, 240, 245, 1) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 6vw;
    width: 6vw;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .9);
  text-transform: capitalize;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, 1);
`;
